import React, { useEffect, useState } from 'react';
import ModerationIcon from "../assets/icons/moderation.svg";
import { marketButtons } from "../constants/marketButtonsName";
import NFT from "../assets/nft/nft_6.svg";
import { auctionData, returnNFTToOwner, startAuction } from "../web3";
import {adminPassword} from "../constants/textContent";
import {Modal, Input} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


function Moderation() {
    const [data, setData] = useState<any>([]);
    const [name, setName] = useState("All");
    const [moderationCategory, setModerationCategory] = useState("");
    const [moderationIndex, setModerationIndex] = useState();
    const [allNftItems, setAllNftItems] = useState<any>([]);
    const [cancelData, setCancelData] = useState<any>([]);
    const [isConnected, setIsConnected] = useState(localStorage.getItem("isConnected"));
    const [password, setPassword] = useState("");
    const [modalOpen, setModalOpen] = useState(true);


    const address: any = localStorage.getItem("address");
    let walletAddress: any = address.slice(1, -1).toString();

    useEffect(() => {
        // Check localStorage every 1 second to detect changes to `isConnected`
        const interval = setInterval(() => {
            const currentIsConnected: any = localStorage.getItem("isConnected");
            if (currentIsConnected !== isConnected) {
                setIsConnected(currentIsConnected);
            }
        }, 10);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [isConnected]);

    useEffect(() => {
        // Fetch data when isConnected changes
        if (isConnected) {
            getAllNftData();
        }
    }, [walletAddress, isConnected]);
    const getAllNftData = () => {
        const data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        };

        fetch("https://red-cost.com/public/public/moderation/data", data)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        console.error("Server error:", text);
                        throw new Error(`Server error: ${response.status} ${response.statusText}`);
                    });
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    let filteredData = jsonData.filter((item: any) => item.is_active === false)
                    setAllNftItems(filteredData);
                } else {
                    console.warn("NFT data not found in the response");
                }
            })
            .catch(error => {
                console.error("Error fetching NFT data:", error);
            });
    };



    const updateNftData = (_wallettAddress: any, _tokenId: any, _tokenAddress: any, _tokenIndex: any, _isActive: any, _auctionEndDate: any) => {
        const data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ walletAddress: _wallettAddress, tokenId: _tokenId, tokenAddress: _tokenAddress, tokenIndex: _tokenIndex, isActive: _isActive, auctionEndDate: _auctionEndDate }),
        };

        fetch("https://red-cost.com/public/public/auction/data", data)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        console.error("Server error:", text);
                        throw new Error(`Server error: ${response.status} ${response.statusText}`);
                    });
                }
                return response.json();
            })
            .then(jsonData => {
                setAllNftItems(jsonData.nft);
            })
            .catch(error => {
                console.error("Error fetching NFT data:", error);
            });
    };

    const canselModeration = (item: any) => {
        returnNFTToOwner(item?.token_index).then((res) => {
            if (res?.status === true) {
                const data = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ walletAddress: item.wallet_address, tokenId: item.token_id, tokenAddress: item.token_address }),
                }
                    ;
                fetch("https://red-cost.com/public/public/nft/cancel", data).then(
                    (response) => response.json()
                );
            }
        })

    }
    // const handleDelete = (idx: any) => {
    // const deletedData = allNftItems.filter((obj: any, i: any) => i !== idx);
    // console.log("newDataaaa=>>>>", deletedData);
    // setAllNftItems(deletedData);
    // };

    const handleInputModerationCategory = (event: any, index: any) => {
        setModerationCategory(event.target.value);
        setModerationIndex(index);
    };

    const handleAcceptModeration = (index: any, item: any) => {
        startAuction(item.token_index, item.initial_price, 25, item.days).then((res) => {
            if (res) {
                auctionData(item?.token_index).then((res) => {
                    updateNftData(item?.wallet_address, item?.token_id, item?.token_address, (item?.token_index).toString(), res?.isActive, res?.endTime)
                    // canselModeration(item)
                });
            }
        })

    };

    const handleDenyModeration = (item: any) => {
        canselModeration(item);
    };

    const handleLogin = () => {
        if (password && password === adminPassword) {
            setModalOpen(false)
        }
    };

    const handleInputPassword = (event: any) => {
        setPassword(event.target.value)
    };

    return (
        <div className="moderation-scroll">
            <div className="moderation-main">
                <div className="moderation-main_title">
                    <img src={ModerationIcon} alt="" />
                    <span>Moderation</span>
                </div>
                {allNftItems?.length ? (
                    <div className="table-container">
                        <div className="table-container_header">
                            <span></span>
                            <span>Name</span>
                            <span>Price</span>
                            <span>Period</span>
                            <span>Author</span>
                            <span style={{ justifyContent: "center" }}>Status</span>
                        </div>
                        <div className="custom-table">
                            {allNftItems?.map((item: any, index: any) => (
                                <div key={index} className="table-container_item">
                                    <span>
                                        <img src={item.token_uri} alt="icon" className="table-icon" />
                                    </span>
                                    <span>{item.token_name}</span>
                                    <span style={{ fontWeight: "600" }}>{item.initial_price}</span>
                                    <span>{item.days}</span>
                                    <span>
                                        <input
                                            type="text"
                                            placeholder={item.category}
                                            className="moderation-table-input"
                                            onInput={(ev) => handleInputModerationCategory(ev, index)}
                                        />
                                    </span>
                                    <span style={{ justifyContent: "center", gap: "12px" }}>
                                        <div className="status-icon" onClick={() => handleAcceptModeration(index, item)}>
                                            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1767_4937)">
                                                    <path
                                                        d="M13.2909 0.795898L14.1315 1.6509L4.82289 11.2055L0.251953 6.51728L1.0925 5.65515L4.82289 9.48129L13.2909 0.795898Z"
                                                        fill="#949494"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1767_4937">
                                                        <rect width="13.8795" height="10.4096" fill="white" transform="translate(0.251953 0.795898)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="status-icon" onClick={() => handleDenyModeration(item)}>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1767_5447)">
                                                    <path
                                                        d="M6.07957 5.19271L10.2057 1.06654L9.31986 0.180664L5.19369 4.30683L1.06752 0.180664L0.181641 1.06654L4.30781 5.19271L0.181641 9.31888L1.06752 10.2048L5.19369 6.07859L9.31986 10.2048L10.2057 9.31888L6.07957 5.19271Z"
                                                        fill="#949494"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1767_5447">
                                                        <rect width="10.0241" height="10.0241" fill="white" transform="translate(0.181641 0.180664)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="table-container empty-table">no data</div>
                )}
            </div>
            <Modal
                open={modalOpen}
                footer={null}
                rootClassName="login-modal"
                title="Login"
                closeIcon={false}
                centered
            >
                <div className="login-body">
                    <Input.Password
                        placeholder="input password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onInput={handleInputPassword}
                        style={{ width: '100%' }}
                    />
                    <button className="login-btn" onClick={handleLogin}>Login</button>
                </div>
            </Modal>
        </div>
    );
}

export default Moderation;
