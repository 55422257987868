import React from "react"
import bgImage from "../assets/bg-image.svg";
import bgImageMobile from "../assets/bg-image-mobile.svg";
import CountDown from "../components/CountDown";
import MainComponent from "../components/MainComponent";
import RedCost from "../assets/images/red-cost-image.svg";
import RedCostImage1 from "../assets/images/red-cost-image1.svg";
import RedCostMob from "../assets/images/red-cost-mob.svg";
import Security from "../components/Security";
import SecurityImage from "../assets/images/security-logo.svg";
import RoadmapWeb from "../components/RoadmapWeb";
import {socialItemsList, socialItemsListBig1, socialItemsListBig2} from "../constants/socialItems";
import RoadmapSlider from "../components/RoadmapSlider";
import RoadmapGsap from "../components/RoadmapGsap";
import RoadmapMobileHome from "../components/RoadmapMobileHome";
import RoadmapMobileAbout from "../components/RoadmapMobileAbout";
import {offerBlockAbout} from "../constants/textContent";

function About () {

    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/IRedcost", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/channels/1217172871613382766/1217172872074760213", '_blank');
                break;
            case "facebook":
                window.open("https://www.facebook.com/profile.php?id=61555985890113", '_blank');
                break;
            case "youtube":
                window.open("https://www.youtube.com/@RedCost", '_blank');
                break;
            case "linkedin":
                window.open("https://www.linkedin.com/in/ivan-redcost-9589212b7/", '_blank');
                break;
            case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
            case "medium":
                window.open("https://medium.com/@redkost", '_blank');
                break;
            case "instagram":
                window.open("https://www.instagram.com/ivanredcost?igsh=b2tka29zNzk4cTQ3", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }

    }

    return (
        <div className="about">
            <div className="about-text-content">
                <div className="about-text">
                    About
                </div>
            </div>
            <div className="mt-36">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText="ABOUT"
                    subtitle="RED COST"
                    content1={"The RED COST Marketplace is an online platform for buying, selling NFT assets, and participating in auctions. Here, you can earn by investing in promising NFT collections and generate additional passive income through staking."}
                    reverse={true}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="WHITEPAPER"
                />
            </div>
            <div className="security-component">
                <div className="security-component-header">
                    <div className="line_1"></div>
                    <div className="security-component-title">we offer you</div>
                    <div className="line_1"></div>
                </div>
                <div className="security-group">
                    {offerBlockAbout.map((item,index)=> {
                        return <Security
                            key={index}
                            image={SecurityImage}
                            subtitle={item.title}
                            content={item.content}
                        />
                    })}
                </div>
                <div className="security-component-button">
                    Explore now
                </div>
            </div>

            <div className="roadmap-home">
                <div className="roadmap-home-header">
                    <div className="roadmap-home-header-line_1"></div>
                    <div className="roadmap-home-header-title">check our</div>
                </div>
                <div className="roadmap-home-header-subtitle">Roadmap</div>
                <div className="web-roadmap-display">
                    <RoadmapSlider />
                </div>
                <div className="mobile-roadmap-display">
                    <RoadmapMobileAbout />
                </div>
            </div>
            <div className="lastMainComponent">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText="What we aspire to"
                    subtitle="Our plans"
                    content1={"In the near future, we plan to release our own limited edition RED COST NFT collection. We also plan to issue our own tokens as an internal payment instrument on our platform. Additionally, every user will be able to earn on their digital assets: buying and selling NFTs on our marketplace through auctions, as well as using staking to receive passive income in the form of dividends."}
                    reverse={true}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="CONTACT"
                />
            </div>
            <div className="social-big-logo-group">
                <div className="security-component-header">
                    <div className="line_1"></div>
                    <div className="security-component-title">follow our socials</div>
                    <div className="line_1"></div>
                </div>
                <div className="social-group-component">
                    {socialItemsListBig1.map((item,key)=> {
                        return <div
                            // className="folow-item"
                            key={key}
                            onClick={() => handleClickSocialItem(item.name)}
                        >
                            <div className="svgbig-container">
                                <a href="#" rel="nofollow">{item.logosvg}</a>
                                <a href="#" rel="nofollow">{item.logoRed}</a>
                            </div>
                        </div>
                    })}
                </div>
                <div className="social-group-component1">
                    {socialItemsListBig2.map((item,key)=> {
                        return <div
                            className="folow-itembig"
                            key={key}
                            onClick={() => handleClickSocialItem(item.name)}
                        >
                            <div className="svgbig-container">
                                <a href="#" rel="nofollow">{item.logosvg}</a>
                                <a href="#" rel="nofollow">{item.logoRed}</a>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className="lastMainComponent1">
                <MainComponent
                    image={RedCostImage1}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText="Welcome to"
                    subtitle="RED COST!"
                    content1={"To join our community of digital asset enthusiasts, simply complete a quick registration using your digital wallet. After that, you’ll have access to trading on the marketplace, and you can also leave your email to stay informed about all upcoming events, especially the releases of new limited collections!"}
                    reverse={false}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="WHITEPAPER"
                />
            </div>

        </div>
    )
}
export default About;