import React, {useState} from "react";
import Add from "../assets/icons/plusadd.svg";
import AssetsNftCard from "../components/AssetsNftCard";
import {Drawer} from "antd";

function Staking () {
    const [open, setOpen] = useState(false);
    const [stakingInfo, setStakingInfo] = useState<any>();
    const [itemIndex, setItemIndex] = useState<number>();

    const nftItems = [
        {
            active: true,
            src: "https://images.unsplash.com/photo-1528991435120-e73e05a58897?auto=format&fit=crop&q=80&w=2787&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            active: false,
            src: "https://images.unsplash.com/photo-1572495532056-8583af1cbae0?auto=format&fit=crop&q=80&w=2787&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            active: true,
            src: "https://images.unsplash.com/photo-1607823489283-1deb240f9e27?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fEFmcmljYW4lMjBmYXNoaW9ufGVufDB8fDB8fHww",
        },
        {
            active: false,
            src: "https://images.unsplash.com/photo-1572495532056-8583af1cbae0?auto=format&fit=crop&q=80&w=2787&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];

    const ItemStatusInfo = (el:any,index:number) => {
        setStakingInfo(el);
        setItemIndex(index)
        showDrawer()
    }

    const handleClaim = ()=> {
        console.log("NGT for CLAIM info=>", stakingInfo)
    }
    const handleBurn = ()=> {
        console.log("NGT for BURN info=>", stakingInfo)
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div className="assets">
            <div className="assets-text-block">
                <p className="discover-text">
                    Staking
                </p>
            </div>
            <div className="assets-group-web">
                {[].map((el:any,index) => <AssetsNftCard
                    key={index}
                    index={index}
                    image={el.src}
                    name="Name"
                    owners="owners"
                    ownwersCount={274}
                    percent={41}
                    houre={24}
                    price={1.56}
                    statusView={false}
                    func={() => {ItemStatusInfo(el,index)}}
                    itemIndex={itemIndex}
                />)}
            </div>
            <div className="assets-group-mob">
                {[].map((el:any,index) => <AssetsNftCard
                    key={index}
                    index={index}
                    image={el.src}
                    name="Name"
                    owners="owners"
                    ownwersCount={274}
                    percent={41}
                    houre={24}
                    price={1.56}
                    statusView={false}
                    itemIndex={itemIndex}
                />)}
            </div>
            <Drawer
                width={434}
                onClose={onClose}
                open={open}
                destroyOnClose
                style={{paddingTop:"111px",paddingRight: "110px",paddingLeft: "50px"}}
                rootClassName="staking-drawer"
                maskStyle={{background:"unset"}}
            >
                <div className="staking-info">
                    <div className="staking-info_title">
                        Staking info
                    </div>
                    <div className="staking-info_content">
                        <div className="staking-info_content_item">
                            <div className="staking-info_content_item_left">Staked</div>
                            <div className="staking-info_content_item_right">xxx</div>
                        </div>
                        <div className="staking-info_content_item">
                            <div className="staking-info_content_item_left">Average APY</div>
                            <div className="staking-info_content_item_right">xxx</div>
                        </div>
                    </div>
                </div>
                <div className="for-claim-title">
                    Available for claim
                </div>
                <input type="text" className="input-claim-count"/>
                <div className="staking-buttons-group">
                    <button className="staking-buttons-group_claim-button" onClick={handleClaim}>Claim</button>
                    <button className="staking-buttons-group_burn-button" onClick={handleBurn}>Burn</button>
                </div>
            </Drawer>

            <div className="mobile-staking-content-main">
                <div className="mobile-staking-content">
                    <div className="mobile-staking-content_header">
                        Staking info
                    </div>
                    <div className="mobile-staking-content_row">
                        <div className="mobile-staking-content_row_left">Staked</div>
                        <div className="mobile-staking-content_row_right">xxx</div>
                    </div>
                    <div className="mobile-staking-content_row">
                        <div className="mobile-staking-content_row_left">Average APY</div>
                        <div className="mobile-staking-content_row_right">xxx</div>
                    </div>
                    <div className="mobile-staking-content_bottom">
                        <div className="mobile-staking-content_bottom_left">Avalible for claim</div>
                        <div className="mobile-staking-content_bottom_right">xxx</div>
                    </div>
                </div>
                <div className="mobile-staking-buttons-group">
                    <div className="mobile-staking-buttons-group_burn">Burn</div>
                    <div className="mobile-staking-buttons-group_claim">Claim</div>
                </div>

            </div>

        </div>
    )
};

export default Staking;