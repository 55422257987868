import Web3 from 'web3';
import { CustodianNFT } from './ABI/CustodianNFTABI';
import { marketABI } from './ABI/marketABI';
import { rdcABI } from './ABI/RDC';

// ERC-721 ABI, including necessary methods

const ERC721_ABI = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            { "name": "", "type": "string" }
        ],
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            { "name": "", "type": "string" }
        ],
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            { "name": "tokenId", "type": "uint256" }
        ],
        "name": "tokenURI",
        "outputs": [
            { "name": "", "type": "string" }
        ],
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            { "name": "to", "type": "address" },
            { "name": "tokenId", "type": "uint256" }
        ],
        "name": "approve",
        "outputs": [],
        "type": "function"
    }
];

const ERC1155_ABI = [
    {
        "constant": true,
        "inputs": [
            { "name": "id", "type": "uint256" }
        ],
        "name": "uri",
        "outputs": [
            { "name": "", "type": "string" }
        ],
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            { "name": "operator", "type": "address" },
            { "name": "approved", "type": "bool" }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "type": "function"
    }
];


// Binance Smart Chain testnet RPC URL
// const bscTestnetProvider = 'https://data-seed-prebsc-1-s1.bnbchain.org:8545';
const bscTestnetProvider = 'https://ethereum-sepolia-rpc.publicnode.com'
const web3 = new Web3(new Web3.providers.HttpProvider(bscTestnetProvider));

// Test contract address on BSC testnet
const RedCostNFTCustodianAddress = "0x19E5E9b2AfDeB8Ce01A37e2B46f8192dA12e571e";
const RedCostMarketAddress = "0xBc067B684Be268a1734A2f23EDAD169d661A6134";
const RDCTokenAddress = "0x6748a2D48156C79ed700e6675B31ac460740FA36";

const contractNFTCustodian = new web3.eth.Contract(CustodianNFT, RedCostNFTCustodianAddress);
const contractMarket = new web3.eth.Contract(marketABI, RedCostMarketAddress);
const contractRDCToken = new web3.eth.Contract(rdcABI, RDCTokenAddress);


//function for get user wallet balance
export async function getBalance(_address) {
    const balanceInWei = web3.eth.getBalance(_address);
    return balanceInWei
}

// Function to get NFT data
export async function getNFT() {
    let nftInfo = []; // Initialize an array to hold NFT information
    try {
        // Fetch the total number of NFTs from the contract
        let nftLength = await contractNFTCustodian.methods.nextNftIndex().call();

        // Loop through each NFT index and fetch the NFT details
        for (let index = 0; index < nftLength; index++) {
            let nftObj = {};
            nftObj = await contractNFTCustodian.methods.nftRegistry(index).call(); // Fetch the NFT details from the contract
            nftInfo.push(nftObj); // Add the NFT details to the array
        }
        return nftInfo; // Return the array of NFT information
    } catch (error) {
        console.error("An error occurred while fetching the NFT:", error); // Log any errors that occur during the process
    }
}

// Function to buy an NFT
export async function buyNFT( _tokenIndex) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        // Estimate the gas required for the transaction
        const estimatedGas = await contractMarket.methods.buy(_tokenIndex).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: account,
            to: contractMarket.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractMarket.methods.buy(_tokenIndex).encodeABI(),
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const startAuction = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction sent! Transaction hash:", startAuction);

        // Wait for the transaction receipt
        const receipt = await waitForReceipt(startAuction);
        // console.log("Transaction receipt:", receipt);

        return receipt; // Return the receipt of the transaction
    } catch (error) {
        console.error("Error in buy:", error); // Log any errors that occur during the process
    }
}

export async function getAuctionData(_tokenId) {
    try {
        let auctionData = await contractMarket.methods.auctions(_tokenId).call();
        return auctionData
    } catch (error) {
        console.error('Error: auctionData', error);
        return null; // Return a default value or handle the error as needed
    }
}

// function for Buying RDC Token
export async function buyRDCToken(_count, _address) {
    try {
        // Convert the ETH value to Wei
        const ethValueInWei = web3.utils.toWei(_count.toString(), 'ether');

        // Estimate the gas required for the transaction
        const estimatedGas = await contractRDCToken.methods.buy().estimateGas({
            from: _address,
            value: ethValueInWei
        });

        alert(ethValueInWei)

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: _address,
            to: contractRDCToken.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractRDCToken.methods.buy().encodeABI(),
            value: web3.utils.toHex(ethValueInWei)  // Use the converted value in Wei
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const buyNFT = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction successful!", buyNFT);
        return buyNFT; // Return the result of the transaction
    } catch (error) {
        console.error("Error Buy:", error); // Log any errors that occur during the process
    }
}

// function for get balanceOf RDC token per user
export async function balanceOfRDCToken(_address) {
    try {
        let balance = await contractRDCToken.methods.balanceOf(_address).call();
        // console.log(balance);
        return balance  // es datan petqa bajanvi 10**18
    } catch (error) {
        console.error('Error: userInfo', error);
        return null; // Return a default value or handle the error as needed
    }
}

// Function to get the token URI
export async function getNftInfo(tokenAddress, tokenId) {
    const contract721 = new web3.eth.Contract(ERC721_ABI, tokenAddress);
    let tokenURI;

    try {
        // Try to fetch ERC-721 tokenURI and symbol
        tokenURI = await contract721.methods.tokenURI(tokenId).call();
        const tokenName = await contract721.methods.name().call();
        const tokenSymbol = await contract721.methods.name().call();
        let tokenInfo =
        {
            token_name: tokenName,
            token_symbol: tokenSymbol,
            token_uri: tokenURI
        }

        return tokenInfo;

    } catch (error) {
        alert("Error fetching ERC-721 URI. Trying ERC-1155 format...");
        const contract1155 = new web3.eth.Contract(ERC1155_ABI, tokenAddress);

        // try {
        //     // Try to fetch ERC-1155 uri
        //     tokenURI = await contract1155.methods.uri(tokenId).call();
        //     console.log("Token URI (ERC-1155):", tokenURI);
        // } catch (error1155) {
        //     console.error("Failed to fetch token URI for both ERC-721 and ERC-1155 standards.");
        //     throw new Error("Unable to fetch token URI. The token might not implement ERC-721 or ERC-1155 standards.");
        // }

    }

}

// Function to approve an ERC-721 token
export async function approveERC721Token(_tokenAddress, _spenderAddress, _tokenId) {
    // Define the contract with ERC-721 ABI
    const contract = new web3.eth.Contract(ERC721_ABI, _tokenAddress);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let status;

    try {
        // Estimate the gas required for the transaction
        const estimatedGas = await contract.methods.approve(_spenderAddress, _tokenId).estimateGas({
            from: account
        });

        // Create the transaction data for approval
        const approveData = contract.methods.approve(_spenderAddress, _tokenId).encodeABI();

        // Define transaction parameters
        const tx = {
            from: account,                     // User's account
            to: _tokenAddress,                 // Token contract address
            data: approveData,                 // Encoded data for the approve function
            gas: web3.utils.toHex(estimatedGas) // Gas limit in hex format
        };

        // Send the transaction using MetaMask
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx]
        });

        // console.log("Transaction sent with hash:", txHash);

        // Wait for transaction confirmation and check status
        const receipt = await waitForTransactionReceipt(txHash);
        return receipt;
    } catch (error) {
        console.error("Error in approval:", error);
    }
}

// Polling function to wait for the transaction receipt
async function waitForTransactionReceipt(txHash) {
    let receipt = null;
    while (receipt === null) {
        receipt = await web3.eth.getTransactionReceipt(txHash);
        if (receipt) return receipt; // Return receipt once it is available
        // console.log("Waiting for transaction confirmation...");
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before polling again
    }
}

// Function to deposit an NFT
export async function depositNFT(_tokenAddress, _tokenId) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];
        // console.log(account);


        // Estimate the gas required for the transaction
        const estimatedGas = await contractNFTCustodian.methods.depositNFT(_tokenAddress, _tokenId).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: account,
            to: RedCostNFTCustodianAddress, // Ensure this points to the correct custodian contract
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractNFTCustodian.methods.depositNFT(_tokenAddress, _tokenId).encodeABI(),
        };

        // console.log("Deposit Transaction Object:", tx); // Log transaction details for debugging

        // Send the transaction
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction sent successfully with hash:", txHash);

        // Wait for transaction confirmation
        const receipt = await waitForTransactionReceipt(txHash);
        let status;
        if (receipt && receipt.status) {
            status = receipt.status
            // console.log("Transaction confirmed successfully!", receipt);
        } else {
            console.log("Transaction failed. Status:", receipt.status);
        }
        // console.log("status Deposit", status);

        return receipt; // Return the transaction hash
    } catch (error) {
        console.error("Error during NFT deposit:", error);
        if (error.message.includes("execution reverted")) {
            console.error("Check if the contract conditions are met (e.g., NFT approval, ownership).");
        }
    }
}

// Function for starting an auction and checking the transaction status
export async function startAuction(_tokenIndex, _price, _step, _days) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];
        let PriceinWei = web3.utils.toWei(_price.toString(), 'ether');
        let stepinWei = web3.utils.toWei(_step.toString(), 'ether');

        // Estimate the gas required for the transaction
        const estimatedGas = await contractMarket.methods.setAuctionParameters(_tokenIndex, PriceinWei, stepinWei, _days).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: account,
            to: contractMarket.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractMarket.methods.setAuctionParameters(_tokenIndex, PriceinWei, stepinWei, _days).encodeABI(),
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const startAuction = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction sent! Transaction hash:", startAuction);

        // Wait for the transaction receipt
        const receipt = await waitForReceipt(startAuction);
        // console.log("Transaction receipt:", receipt);

        if (receipt.status) {
            console.log("Transaction was successful!");
        } else {
            console.log("Transaction failed.");
        }

        return receipt; // Return the receipt of the transaction
    } catch (error) {
        console.error("Error in startAuction:", error); // Log any errors that occur during the process
    }
}

// Helper function to wait for the transaction receipt
async function waitForReceipt(txHash) {
    while (true) {
        const receipt = await web3.eth.getTransactionReceipt(txHash);
        if (receipt) {
            return receipt;
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
    }
}


//function for get tokenIndex
export async function getNextNFTIndex() {
    try {
        // Fetch the total number of NFTs from the contract
        let nftLength = await contractNFTCustodian.methods.nextNftIndex().call();

        // console.log("nftInfo", nftLength);
        return nftLength; // Return the array of NFT information
    } catch (error) {
        console.error("An error occurred while fetching the NFT:", error); // Log any errors that occur during the process
    }
}

//fuction get auction info
export async function auctionData(token_index) {
    try {
        // Fetch the total number of NFTs from the contract
        let auctionInfo = await contractMarket.methods.auctions(token_index).call();
        // console.log(auctionInfo);

        return auctionInfo; // Return the array of NFT information
    } catch (error) {
        console.error("An error occurred while fetching the NFT:", error); // Log any errors that occur during the process
    }
}

export async function decreaseAuctionPrice(_tokenIndex) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        // Estimate the gas required for the transaction
        const estimatedGas = await contractMarket.methods.decreaseAuctionPrice(_tokenIndex).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: account,
            to: contractMarket.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractMarket.methods.decreaseAuctionPrice(_tokenIndex).encodeABI(),
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const startAuction = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction sent! Transaction hash:", startAuction);

        // Wait for the transaction receipt
        const receipt = await waitForReceipt(startAuction);
        // console.log("Transaction receipt:", receipt);

        if (receipt.status) {
            // console.log("Transaction was successful!");

            // Assuming the contract emits an event called "AuctionPriceDecreased"
            const events = receipt.logs.filter(log =>
                log.address.toLowerCase() === contractMarket.options.address.toLowerCase()
            );

            events.forEach(log => {
                const decodedLog = web3.eth.abi.decodeLog(
                    [
                        { type: 'uint256', name: 'tokenIndex' },
                        { type: 'uint256', name: 'newPrice' }
                    ],
                    log.data,
                    log.topics.slice(1)
                );

                // console.log("Event received from contract:", decodedLog);
            });
        } else {
            console.log("Transaction failed.");
        }

        return receipt; // Return the receipt of the transaction
    } catch (error) {
        console.error("Error in decreaseAuctionPrice:", error); // Log any errors that occur during the process
    }
}


export async function approveToken(_count) {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];

    try {
        // Ensure _count is a string and in the correct units for token amount
        const tokenAmount = web3.utils.toWei(_count.toString(), 'ether');

        // Estimate the gas required for the transaction
        const estimatedGas = await contractRDCToken.methods.approve(RedCostMarketAddress, tokenAmount).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object without the 'value' field
        const tx = {
            from: account,
            to: contractRDCToken.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractRDCToken.methods.approve(RedCostMarketAddress, tokenAmount).encodeABI(),
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const approveToken = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction successful!", approveToken);
        return approveToken; // Return the result of the transaction
    } catch (error) {
        console.error("Error Approve:", error); // Log any errors that occur during the process
    }
}

export  async function returnNFTToOwner(_tokenIndex) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        // Estimate the gas required for the transaction
        const estimatedGas = await contractNFTCustodian.methods.returnNFTToOwner(_tokenIndex).estimateGas({
            from: account,
        });

        // Get the current gas price
        const gasPrice = await web3.eth.getGasPrice();

        // Create the transaction object
        const tx = {
            from: account,
            to: contractNFTCustodian.options.address,
            gas: web3.utils.toHex(estimatedGas),
            gasPrice: web3.utils.toHex(gasPrice),
            data: contractNFTCustodian.methods.returnNFTToOwner(_tokenIndex).encodeABI(),
        };

        // console.log("Transaction Object:", tx); // Debugging: log the transaction object

        // Send the transaction
        const startAuction = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [tx],
        });

        // console.log("Transaction sent! Transaction hash:", startAuction);

        // Wait for the transaction receipt
        const receipt = await waitForReceipt(startAuction);
        // console.log("Transaction receipt:", receipt);

        return receipt; // Return the receipt of the transaction
    } catch (error) {
        console.error("Error in decreaseAuctionPrice:", error); // Log any errors that occur during the process
    }
}