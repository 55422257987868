import React from "react"
import bgImage from "../assets/bg-image.svg";
import bgImageMobile from "../assets/bg-image-mobile.svg";
import CountDown from "../components/CountDown";
import MainComponent from "../components/MainComponent";
import RedCost from "../assets/images/red-cost.svg";
import RedCostNew from "../assets/images/red-cost-image.svg";
import RedCostMob from "../assets/images/red-cost-mob.svg";
import RedCostCenter from "../assets/images/red-cost-center.svg";
import Walf from "../assets/Walf.svg";
import Security from "../components/Security";
import SecurytyImage from "../assets/images/security-logo.svg";
import RoadmapWeb from "../components/RoadmapWeb";
import {socialItems, socialItemsList} from "../constants/socialItems";
import SecurityDiscover from "../components/SecurityDiscover";
import MainComponent2 from "../components/MainComponent2";
import Offer from "../components/Offer";
import RoadmapWebJoin from "../components/RoadmapWebJoin";
import { useNavigate } from "react-router-dom";
import telTop from "../assets/tel-top.svg";
import telBottom from "../assets/tel-bottom.svg";
import telInner from "../assets/tel-inner.svg";
import emailTop from "../assets/email-top.svg";
import emailBottom from "../assets/email-bottom.svg";
import addressTop from "../assets/address-top.svg";
import socialInner from "../assets/social-inner.svg";
import socialTop from "../assets/social-top.svg";

function Contacts() {

    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/IRedcost", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/channels/1217172871613382766/1217172872074760213", '_blank');
                break;
            case "facebook":
                window.open("https://www.facebook.com/profile.php?id=61555985890113", '_blank');
                break;
            case "youtube":
                window.open("https://www.youtube.com/@RedCost", '_blank');
                break;
            case "linkedin":
                window.open("https://www.linkedin.com/in/ivan-redcost-9589212b7/", '_blank');
                break;
            case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
            case "medium":
                window.open("https://medium.com/@redkost", '_blank');
                break;
            case "instagram":
                window.open("https://www.instagram.com/ivanredcost?igsh=b2tka29zNzk4cTQ3", '_blank');
                break;
            case "telegram":
                window.open("https://t.me/redcosters", '_blank');
                break;
            case "threads":
                window.open("https://www.threads.net/@ivanredcost", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }
    };

    const openMap = () => {
        window.open("https://www.google.com/maps", "_blank")
    }

    return (
        <div className="contacts">
            <div className="contact-text-block">
                <div className="contact-text">
                    Contact
                </div>
            </div>
            <div className="contacts-first-item">
            </div>

            <div className="responsive-main-top">
                <MainComponent
                    image={RedCostNew}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText="Join"
                    subtitle="Red Cost"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    reverse={true}
                    isInput={false}
                    noButton={true}
                />
            </div>
            {/*ADRESS*/}

            <div className="actions-group">
                <div className="address">
                    <img src={addressTop} alt="" className="address-top" />
                    <div className="open-button" onClick={openMap}>
                        <a rel="nofollow">Open in maps</a>
                    </div>
                    <div className="address-title">
                        Address
                    </div>
                </div>

                <div className="telephone">
                    <img src={telInner} alt="" className="tel-inner" />
                    <img src={telTop} alt="" className="tel-top" />
                    <div className="address-title">
                        +0 0000 00 00
                    </div>
                    <img src={telBottom} alt="" className="tel-bottom" />
                </div>

                <div className="email">
                    <img src={emailTop} alt="" className="email-top" />
                    <div className="address-title">
                        mailexample@gmail.com
                    </div>
                    <img src={emailBottom} alt="" className="email-bottom" />
                </div>

                <div className="social">
                    <img src={socialInner} alt="" className="social-inner" />
                    <img src={telTop} alt="" className="social-top" />
                    <div className="social-contact-group">
                        <div className="social-contact-group-logo">
                            {socialItems.map((socialItem: any, index) => {
                                return <div
                                    key={index}
                                    onClick={() => handleClickSocialItem(socialItem.name)}
                                >
                                    <div className="svg-container">
                                        <img src={socialItem.logo} alt="" className="image1"/>
                                        <img src={socialItem.logored} alt="" className="image2"/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <img src={telBottom} alt="" className="social-bottom" />
                </div>

            </div>



            <div className="lastMainComponent1">
            <MainComponent
                image={RedCost}
                imageMobile={RedCostMob}
                title={true}
                titleText=""
                subtitle="TiTle"
                content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                content3={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                reverse={false}
                isInput={false}
                noButton={true}
            />
            </div>

            <div className="discover-first-item" style={{ marginTop: "160px" }}>
                <div className="discover-digital-art-contacts">
                    <div className="discover-header-side">
                        Enter The New World<br />of Digital Art
                    </div>
                    <div className="discover-bottom-side">
                        <div className="discover-bottom-side-left">
                            <div className="discover-bottom-side-left-header">
                                The marketplace for those who know<br />the true value of digital assets.
                            </div>
                            <div className="discover-bottom-side-left-header_buttons">
                                <div className="discover-left-button">Whitepaper</div>
                                <div className="discover-right-button">About</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="discover-first-item-mobile" style={{ marginTop: "70px" }}>
                <div className="discover-digital-art-contacts">
                    <div className="discover-header-side">
                        Enter The New World<br />of Digital Art
                    </div>
                    <div className="discover-bottom-side">
                        <div className="discover-bottom-side-left">
                            <div className="discover-bottom-side-left-header">
                                The marketplace for those who know<br />the true value of digital assets.
                            </div>
                            <div className="discover-bottom-side-left-header_buttons">
                                <div className="discover-left-button">Whitepaper</div>
                                <div className="discover-right-button">About</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Contacts;