/*
pages
*/
import AuthLayout from "../auth/AuthLayout";
import About from "../pages/About";
import Discover from "../pages/Discover";
import Error from "../pages/Error";
import Investors from "../pages/ForInvestors";
import Contacts from "../pages/Contacts";
import AirDrop from "../pages/AirDrop";
import Market from "../pages/Market";
import NFTDetails from "../pages/NFTDetails";
import Assets from "../pages/Assets";
import Sale from "../pages/Sale";
import NFTInfo from "../pages/NFTInfo";
import Staking from "../pages/Staking";
import Moderation from "../admin/Moderation";
import Collection from "../pages/Collection";
import NFTDetailsCollection from "../pages/NFTDetailsCollection";


interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  // { path: "/", component: AuthLayout },
  { path: "/", component: Market },
  { path: "/about", component: About },
  { path: "/discover", component: Discover },
  { path: "/airdrop", component: AirDrop },
  { path: "/investors", component: Investors },
  { path: "/contacts", component: Contacts },
  // { path: "/market", component: Market },
  { path: "/details", component: NFTDetails },
  { path: "/collection", component: Collection },
  { path: "/collection/details", component: NFTDetailsCollection },
  { path: "/assets", component: Assets },
  { path: "/assets/sale", component: Sale },
  { path: "/info", component: NFTInfo },
  { path: "/staking", component: Staking },
  { path: "/admin/moderation", component: Moderation },
  { path: "*", component: Error },

];

export {
  publicRoutes,
};
